function changeQtyBtn(e) {
    const $this = $(e.currentTarget);
    const $qtyInput = $this.siblings('.js-qty-number');
    const currentVal = parseInt($qtyInput.val(), 10);
    const newVal = $this.is('.js-qty-plus') ? currentVal + 1 : currentVal - 1;

    if (newVal <= $qtyInput.attr('max') && newVal >= $qtyInput.attr('min')) {
        $qtyInput.val(newVal);
        $('.modal.show .update-cart-url').data('selected-quantity', newVal);
    }
}

function changeQtyInput(e) {
    const $this = $(e.currentTarget);
    const qty = parseInt($this.val(), 10);
    const maxQty = $this.attr('max');
    const minQty = $this.attr('min');

    if (!qty || qty < minQty) {
        $this.val(minQty);
    } else if (qty && qty > maxQty) {
        $this.val(maxQty);
    }
}

function bindEvents() {
    $('.js-qty-btn').on('click.changeqty', changeQtyBtn);
    $('.js-qty-number').on('change.changeqty', changeQtyInput);
}

module.exports = {
    bindEvents,
};
