function checkForMajority() {
    const majority = window.localStorage.getItem('majority');

    if (!majority || majority === 'false') {
        // If user didn't confirm the majority
        // or when a user first time visit,
        // or if the user session is changed/expired
        // then show the majority confirmation
        window.localStorage.setItem('majority', false);
        $('#majorityConfirmationModal').modal('show');
    }
}

function bindEvents() {
    $('.js-majority-confirm').on('click', () => {
        window.localStorage.setItem('majority', true);
    });
}

module.exports = {
    checkForMajority,
    bindEvents,
};
