module.exports = () => {
    $(document).on('click.readmore', '.read-more__button', (e) => {
        const $button = $(e.currentTarget);
        const $parent = $button.parent();

        $parent.find('.read-more__short').addClass('read-more__short--show');
        $parent.find('.read-more__full-text').show();
        $button.hide();
    });
};
