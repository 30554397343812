const focusHelper = require('base/components/focus');
const base = require('../product/base');
const quantitySelector = require('../product/quantitySelector');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?')
        + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `<div class="alert alert-danger alert-dismissible valid-cart-error
                fade show" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            window.location.reload();
        }
    } else if (!data.pendingMembershipCustomize) {
        $('.checkout-btn').removeClass('disabled');
    }

    if ('pendingMembershipCustomize' in data) {
        $('.js-membership-description').html(data.productCardMembershipDescription);
        $('.js-customization-proceed').toggleClass('d-none', data.pendingMembershipCustomize);
        $('.js-customization-proceed-pending').toggleClass('d-none', !data.pendingMembershipCustomize);
    }

    if (data.approachingDraftOrderThreshold.isDraftOrder && !data.approachingDraftOrderThreshold.thresholdMet) {
        $('.checkout-btn').addClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems,
    });
    if (data.totals.totalSavings.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`- ${data.totals.totalSavings.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }
    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`- ${data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        } else {
            $(`.item-${item.UUID}`).empty();
        }
        $(`.uuid-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.line-item-price-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `<div class="alert alert-danger alert-dismissible valid-cart-error
        fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the approaching draft order threshold message
 * @param {Object} approachingDraftOrderThreshold - updated approaching draft order threshold for the cart
 */
function updateApproachingThreshold(approachingDraftOrderThreshold) {
    let html = '';
    $('.approaching-draft-order-threshold').empty();
    if (approachingDraftOrderThreshold.isDraftOrder) {
        html = `<p>${approachingDraftOrderThreshold.clubMessage}</p>
        <div class="progress-bar"><div class="progress-bar-active" style="width: ${approachingDraftOrderThreshold.thresholdProgress}%;"></div></div>
        <p>${approachingDraftOrderThreshold.thresholdMessage}</p>`;
    }
    $('.approaching-draft-order-threshold').append(html);
}
/**
 * re-renders the approaching shipping discount message
 * @param {*} approachingShippingDiscount  - updated approaching shipping discount for the cart
 * @returns {void}
 */
function updateApproachingShippingDiscount(approachingShippingDiscount) {
    let html = '';
    $('.approaching-shipping-discount').empty();
    if (approachingShippingDiscount.isAvailable) {
        html = `<div class="progress-bar"><div class="progress-bar-active" style="width: ${approachingShippingDiscount.progress}%;"></div></div>
        <p>${approachingShippingDiscount.message}</p>`;
    }
    $('.approaching-shipping-discount').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(`.availability-${lineItem.UUID}`).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach((message) => {
                    if (message !== 'In Stock') {
                        messages += `<p class="line-item-attributes">${message}</p>`;
                    }
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += `<p class="line-item-attributes line-item-instock-date">
                    ${lineItem.availability.inStockDate}
                    </p>`;
            }
        }

        $(`.availability-${lineItem.UUID}`).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (let i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, (item) => item.UUID === uuid);

    if (lineItem.variationAttributes) {
        const colorAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'color');

        if (colorAttr) {
            const colorSelector = `.Color-${uuid}`;
            const newColor = `Color: ${colorAttr.displayValue}`;
            $(colorSelector).text(newColor);
        }

        const sizeAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'size');

        if (sizeAttr) {
            const sizeSelector = `.Size-${uuid}`;
            const newSize = `Size: ${sizeAttr.displayValue}`;
            $(sizeSelector).text(newSize);
        }

        const imageSelector = `.product-info.uuid-${uuid} .item-image > img`;
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(id) {
    const modalId = id || 'editProductModal';

    if ($(`#${modalId}`).length !== 0) {
        $(`#${modalId}`).remove();
    }

    const htmlString = `
        <div class="modal fade" id="${modalId}" tabindex="-1" role="dialog">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog quick-view-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>`;
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html, isGiftCertificate) {
    const $html = $('<div>').append($.parseHTML(html));
    let body;
    if (isGiftCertificate) {
        body = $html.find('.product-gift-certificate');
    } else {
        body = $html.find('.product-quickview');
    }
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl, isGiftCertificate, fillModalId) {
    const modalId = fillModalId || 'editProductModal';

    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: (data) => {
            const parsedHtml = parseHtml(data.renderedTemplate, isGiftCertificate);

            $(`#${modalId} .modal-body`).empty();
            $(`#${modalId} .modal-body`).html(parsedHtml.body);
            $(`#${modalId} .modal-footer`).html(parsedHtml.footer);
            $(`#${modalId} .modal-header .close .sr-only`).text(data.closeButtonText);
            $(`#${modalId} .enter-message`).text(data.enterDialogMessage);
            $(`#${modalId}`).modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: () => {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.js-product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const urlParams = {
            pid: productID,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success: (data) => {
                if (data.basket.items.length === 0) {
                    window.location.reload();
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }
                    $(`.uuid-${uuid}`).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    updateApproachingThreshold(data.basket.approachingDraftOrderThreshold);
                    updateApproachingShippingDiscount(data.basket.approachingShippingDiscount);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: (err) => {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        window.localStorage.setItem('isCustomizeOrder', false);
    });

    $('body').on('hidden.bs.modal', '#removeProductModal', function () {
        const $deleteBtn = $(this).find('.cart-delete-confirmation-btn');
        const $qty = $(`[name="quantity-${$deleteBtn.data('uuid')}"]`);
        const preSelectQty = parseInt($qty.data('pre-select-qty'), 10);

        $qty.val(preSelectQty);
    });

    $('body').on('change', '.quantity-form > .form-select-hollow > .quantity', function () {
        const preSelectQty = $(this).data('pre-select-qty');
        const quantity = $(this).val();
        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');

        // If user selects 0, then removes product from cart
        if (quantity <= 0) {
            const actionUrl = $(this).data('action-remove');
            const productName = $(this).data('name');

            confirmDelete(actionUrl, productID, productName, uuid);
            $('#removeProductModal').modal('show');

            return;
        }

        const urlParams = {
            pid: productID,
            quantity,
            uuid,
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: (data) => {
                $(`.quantity[data-uuid="${uuid}"]`).val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateApproachingThreshold(data.approachingDraftOrderThreshold);
                updateApproachingShippingDiscount(data.approachingShippingDiscount);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    window.location.reload();
                }
            },
            error: (err) => {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            },
        });
    });

    $('.shippingMethods').change(function () {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id'),
        };

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: (data) => {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateApproachingThreshold(data.approachingDraftOrderThreshold);
                    updateApproachingShippingDiscount(data.approachingShippingDiscount);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: (err) => {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: (data) => {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateApproachingThreshold(data.approachingDraftOrderThreshold);
                    updateApproachingShippingDiscount(data.approachingShippingDiscount);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: (err) => {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.js-coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success: (data) => {
                $(`.coupon-uuid-${uuid}`).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateApproachingThreshold(data.approachingDraftOrderThreshold);
                updateApproachingShippingDiscount(data.approachingShippingDiscount);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: (err) => {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: (data) => {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: () => {
                $.spinner().stop();
            },
        });
    });

    // handling edit gift certificate click
    $('body').on('click', '.cart-page .gift-card-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement('editGiftCertModal');
        fillModalElement(editProductUrl, true, 'editGiftCertModal');
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.js-product-edit-subscription', function (e) {
        e.preventDefault();
        $(`#editProductSubscriptionModal-${$(this).data('uuid')}`).modal('show');
    });

    $('body').on('click', '.edit-membership-confirmation-btn, .js-update-membership', function (e) {
        e.preventDefault();
        $.spinner().start();
        const clubSelectedOption = $('.js-club-select option:selected');
        const wineClubClass = `.js-customize-membership-form-${clubSelectedOption.val()}`;
        const $form = $(wineClubClass);
        $(this).attr('disabled', true);
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: (data) => {
                if (data.success) {
                    $form.closest('.modal').modal('hide');
                    if (data.basket) {
                        updateCartTotals(data.basket);
                    }
                    $('.js-membership-description').html(data.productCardMembershipDescription);
                    $('.js-customization-proceed').toggleClass('d-none', data.pendingMembershipCustomize);
                    $('.js-customization-proceed-pending').toggleClass('d-none', !data.pendingMembershipCustomize);
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        $('.edit-membership-confirmation-btn, .js-update-membership').removeAttr('disabled');
                    }
                }
                $.spinner().stop();
            },
            error: () => {
                $.spinner().stop();
                $('.edit-membership-confirmation-btn, .js-update-membership').removeAttr('disabled');
            },
        });
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select',
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'),
        );
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('available'));

            const allReady = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('ready-to-order'));

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        const selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId,
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: (data) => {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateApproachingThreshold(data.cartModel.approachingDraftOrderThreshold);
                    updateApproachingShippingDiscount(data.cartModel.approachingShippingDiscount);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: (err) => {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        }
    });

    quantitySelector.bindEvents();

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
