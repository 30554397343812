const { assign } = require('lodash');

const players = {};
let iframes;

function injectApiScript() {
    const script = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];

    script.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
}

function checkAutoplay(iframe) {
    return iframe.classList.contains('autoplay');
}

function checkLoop(iframe) {
    return iframe.classList.contains('loop');
}

function onPlayerReady(event) {
    const iframe = event.target.getIframe();
    const isAutoplay = checkAutoplay(iframe);

    if (isAutoplay) {
        event.target.playVideo();
    }
}

function onPlayerStateChange(event) {
    const iframe = event.target.getIframe();
    const isAutoplay = checkAutoplay(iframe);
    const isLoop = checkLoop(iframe);

    if (event.data === window.YT.PlayerState.PLAYING) {
        $(iframe).siblings('.js-video-poster').addClass('hide');
    }

    if (event.data === window.YT.PlayerState.UNSTARTED) {
        if (isAutoplay) {
            event.target.playVideo();
        }
    }

    if (event.data === window.YT.PlayerState.ENDED) {
        if (isLoop) {
            event.target.playVideo();
        }
    }
}

function onYouTubeIframeAPIReady() {
    iframes.forEach((element) => {
        const isAutoplay = checkAutoplay(element);
        const playerVars = {
            enablejsapi: 1,
            modestbranding: 1,
            iv_load_policy: 3,
            origin: element.dataset.origin,
            playsinline: 1,
        };

        if (isAutoplay) {
            assign(playerVars, {
                autoplay: 1,
                controls: 0,
                disablekb: 1,
                mute: 1,
            });
        }

        /* eslint-disable */
        const player = new window.YT.Player(element.id, {
            videoId: element.dataset.videoId,
            playerVars: playerVars,
            events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange,
            },
        });
        /* eslint-enable */

        players[element.id] = player;
    });
}

function initYoutube() {
    iframes = Array.from(document.getElementsByClassName('js-video-youtube'));

    if (iframes.length <= 0) {
        return;
    }

    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    injectApiScript();
}

function handlePlayVideoClick(e) {
    const $this = $(e.currentTarget);
    const $container = $this.closest('.video-container');
    const $iframe = $container.find('iframe');
    const $video = $container.find('video');

    if ($iframe.length) {
        const player = players[$iframe.attr('id')];

        player.playVideo();
    } else if ($video.length) {
        const player = $video[0];

        player.play();
        player.controls = true;
    }

    $this.hide()
        .siblings('.js-video-poster')
        .hide();
}

function bindEvents() {
    $('.js-btn-play-video').on('click', handlePlayVideoClick);
}

module.exports = {
    bindEvents,
    initYoutube,
};
