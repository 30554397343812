const processInclude = require('base/util');
window.jQuery = require('jquery');

window.$ = window.jQuery;

$(document).ready(() => {
    processInclude(require('./components/menu'));
    processInclude(require('./components/sideMenu'));
    processInclude(require('./components/majorityConfirmation'));
    processInclude(require('./components/cookie'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('giftcertificate/giftcert/giftcert'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('gtm/init'));
    processInclude(require('./components/modules'));
    processInclude(require('commercepayments/components/payment'));
    processInclude(require('./product/recommendations'));
    processInclude(require('./components/videos'));
});

require('./thirdParty/bootstrap');
require('base/components/spinner');
require('slick-carousel/slick/slick.min.js');
