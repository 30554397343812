const { getSessionIDs } = require('./functions');

/**
 * Save the session ID on local storage
 */
function saveAndAccept() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    const urlAccept = $('.tracking-consent').data('accept');

    $.spinner().start();
    $.ajax({
        url: urlAccept,
        dataType: 'json',
    })
        .done(() => {
            window.localStorage.setItem('accept_cookies', true);
        })
        .fail(() => {
            window.localStorage.setItem('accept_cookies', false);
        })
        .always(() => {
            $.spinner().stop();
        });
}

function checkSessionID() {
    const sids = getSessionIDs();

    if (!sids.previousSessionID && sids.currentSessionID) {
        // When a user first time visit the home page,
        // set the previousSessionID to currentSessionID
        // and Show the cookie alert
        window.localStorage.setItem('previousSid', sids.currentSessionID);
    } else if (sids.previousSessionID && sids.previousSessionID !== sids.currentSessionID) {
        // Clear the previousSessionID from localStorage
        // when user session is changed or expired
        window.localStorage.removeItem('previousSid');
    }
}

function showCookieWarning() {
    const $cookieMsg = $('.cookie-warning-messaging');

    if ($cookieMsg.length) {
        const hasAccepted = window.localStorage.getItem('accept_cookies');

        if (!hasAccepted || hasAccepted === 'false') {
            $cookieMsg.show();
        }

        $cookieMsg.find('.alert').on('close.bs.alert', saveAndAccept);
    }
}

module.exports = {
    showCookieWarning,
    checkSessionID,
};
