const { breakpoints } = require('site/components/functions');
const { animateArrows } = require('site/components/carouselAnimations');

function initRecommendedSlider() {
    $('.js-product-recommendations').each(function () {
        const $this = $(this);

        const slickOptions = {
            mobileFirst: true,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            slidesToShow: 1,
            centerPadding: '18%',
            autoplay: false,
            prevArrow: '<div class="d-none d-md-block arrow__container arrow__container--prev"><span class="arrow__diamond"></span><button type="button" class="slick-prev">Previous</button></div>',
            nextArrow: '<div class="d-none d-md-block arrow__container arrow__container--next"><span class="arrow__diamond"></span><button type="button" class="slick-next">Next</button></div>',
            responsive: [
                {
                    breakpoint: breakpoints.md,
                    settings: {
                        dots: true,
                        centerMode: false,
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: breakpoints.md,
                    settings: {
                        dots: false,
                        centerMode: false,
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: breakpoints.xl,
                    settings: {
                        dots: false,
                        centerMode: false,
                        slidesToShow: 4,
                    },
                },
            ],
        };

        $this.slick(slickOptions);
        animateArrows($this);
        $this.on('breakpoint', () => {
            animateArrows($this);
        });
    });
}

module.exports = {
    initRecommendedSlider,
};
