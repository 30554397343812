'use strict';

var scrollAnimate = require('./scrollAnimate');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 5000);
}

function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var $button = $(this);
        var url = $button.data('href');
        var emailId = $('input[name=hpEmailSignUp]').val();
        $.spinner().start();
        $(this).attr('disabled', true);

        url = appendToUrl(url, {
            email: emailId
        });

        if (!validateEmail(emailId || !emailId)) {
            $('#newsletterSubscribe .js-invalid-email').removeClass('d-none');
            $('#newsletterSubscribe .subscribed').addClass('d-none');
            $.spinner().stop();
            $(this).attr('disabled', false);
            return;
        }
        
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
        })
            .done((data) => {
            })
            .always(() => {
                $('input[name=hpEmailSignUp]').val('');
                $('#newsletterSubscribe .js-invalid-email').addClass('d-none');
                $('#newsletterSubscribe .subscribed').removeClass('d-none');
                $(this).attr('disabled', false);
                $.spinner().stop();
            });
        //}
    });
};
