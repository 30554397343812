let updateMiniCart = true;
const scrollAnimate = require('base/components/scrollAnimate');
const cart = require('../cart/cart');

function loadMinicartAndShow(target) {
    const url = target.data('action-url');
    const count = parseInt(target.find('.minicart-quantity').text(), 10);
    const $popover = target.find('.popover');

    if (count !== 0 && target.find('.popover.show').length === 0) {
        if (!updateMiniCart) {
            $popover.addClass('show');
            return;
        }

        $popover.addClass('show')
            .spinner().start();

        $.get(url, (data) => {
            $popover.empty()
                .append(data)
                .spinner().stop();
            updateMiniCart = false;
        });
    }
}

module.exports = () => {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        const $this = $(this);

        if (count && $.isNumeric(count.quantityTotal)) {
            $this.find('.minicart-quantity').text(count.quantityTotal);
            $this.find('.minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems,
            });
            scrollAnimate($this.closest('.header'));
            loadMinicartAndShow($this);
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        loadMinicartAndShow($(this));
    });

    $('body').on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('.minicart').on('mouseleave focusout', (event) => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            window.location.reload();
        }
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });
};
