const { debounce } = require('lodash');
const keyboardAccessibility = require('base/components/keyboardAccessibility');
const { getStyle, stripeUnit } = require('./functions');

let bannerInterval;

const clearSelection = (element) => {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

function toggleDropdown(target, dropdownHeight) {
    const $dropdown = target.closest('.dropdown');
    const $dropdownContainer = target.siblings('.dropdown-menu__container');
    const $dropdownMenu = $dropdownContainer.find('.dropdown-menu');
    const toggle = !!dropdownHeight;

    $dropdown.closest('.dropdown').toggleClass('show', toggle);
    $dropdownContainer.height(dropdownHeight);
    $dropdownMenu.attr('aria-hidden', !toggle);
    target.attr('aria-expanded', toggle);
}

function headerBannerAnim() {
    const $content = $('.js-header-banner');
    const $banner = $content.parent();
    const lineHeight = stripeUnit(getStyle($content[0], 'line-height'), 'px');
    const lines = $content.innerHeight() / lineHeight;

    if (lines > 1) {
        let currentLine = 0;

        $content.append($content.children().first().clone());
        bannerInterval = setInterval(() => {
            // Only animate when tab is focused
            if (!document.hidden) {
                currentLine++;
                const scrollPos = lineHeight * currentLine;

                $banner.animate({ scrollTop: scrollPos }, 500, () => {
                    if (currentLine === lines) {
                        $content.append($content.children().first().clone());
                        $content.children().first().remove();
                        $content.scrollTop(0);
                        currentLine = 0;
                    }
                });
            }
        }, 4500);
    }
}

function resetHeaderBannerAnim() {
    if (bannerInterval) {
        clearInterval(bannerInterval);
    }

    const $content = $('.js-header-banner');

    $content.children().not(':first').remove();
    $content.parent().scrollTop(0);
    headerBannerAnim();
}

function bindHeaderBannerEvents() {
    $(window).on('load resize orientationchange', debounce(resetHeaderBannerAnim, 100));
}

function setHeaderBannerVisibility(show) {
    const $headerBanner = $('.header-banner');

    window.sessionStorage.setItem('hide_header_banner', !show);
    $headerBanner.toggleClass('d-none', !show);
    $('.page').toggleClass('has-header-banner', show && $headerBanner.length > 0);
}

module.exports = function () {
    const hideHeaderBanner = window.sessionStorage.getItem('hide_header_banner');

    setHeaderBannerVisibility(!hideHeaderBanner || hideHeaderBanner === 'false');

    $('.header-banner .close').on('click', () => {
        setHeaderBannerVisibility(false);
    });

    keyboardAccessibility(
        '.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: (menuItem) => { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: (menuItem) => { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: (menuItem) => { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: (menuItem) => { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: (menuItem) => { // escape
                const parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            },
        },
        function () {
            return $(this).parent();
        },
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const $dropdownContainer = $this.siblings('.dropdown-menu__container');
        let dropdownHeight = $dropdownContainer.innerHeight();

        if (dropdownHeight) {
            // If is already open, then close it
            dropdownHeight = 0;
        } else {
            // Else close all others and open this one
            toggleDropdown($this.closest('.navbar-nav').find('.dropdown-toggle'), 0);
            dropdownHeight = $dropdownContainer.find('.dropdown-menu').innerHeight();
        }

        toggleDropdown($this, dropdownHeight);
    });

    $('.navbar>.close-menu>.close-button').on('click', (e) => {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', (e) => {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').click((e) => {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    keyboardAccessibility(
        '.navbar-header .user',
        {
            40: ($popover) => { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: ($popover) => { // up
                if ($popover.children('a').first().is(':focus')) {
                    $popover.focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: () => { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: () => { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
        },
        () => {
            const $popover = $('.user .popover li.nav-item');
            return $popover;
        },
    );

    $('.navbar-header .user').on('mouseenter focusin', () => {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', () => {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });

    $('body').on('click', '#myaccount', (event) => {
        event.preventDefault();
    });

    bindHeaderBannerEvents();
};
